<template>
  <section class="room-section">
    <header class="py-4" v-if="material && !material.locked">
      <div class="container">
        <b-row class="align-items-center">
          <b-col lg="2" cols="3">
            <img src="/images/logo_h.png" class="logo" alt="logo"/>
          </b-col>
          <b-col class="text-center">
            <div class="position-relative">
              <b-row class="align-items-center">
                <b-col cols="auto" v-if="currentSlideHasLives">
                  <div class="heart-text">
                    <HeartSvg/>{{ livesLeft }}
                  </div>
                </b-col>
                <b-col>
                  <b-progress :value="slideIndex"
                              :max="slides.length"
                              class="b-progress"></b-progress>
                  <div class="progress-indicator">{{ (slideIndex + 1) }} / {{ slides.length }}</div>
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col lg="2" cols="3" class="text-right">
            <router-link class="text-muted"
                         :to="{ name: 'StudentLightCourseLessons', params: { courseId: module.light_course_id, moduleId: module.id, lessonId: lesson.id } } ">
              {{ $t('exit') }}
            </router-link>
          </b-col>
        </b-row>
      </div>
    </header>
    <main>
      <div v-if="material && !material.locked">
        <div class="container">
          <transition name="component-fade" mode="out-in">
            <TaskComponentsParser v-if="currentSlide && currentSlide.components"
                                  class="slidehtml"
                                  :slide="slideIndex"
                                  :slidecomponents="currentSlide.components"
                                  :host="false"
                                  view="lesson"
                                  source="light"
                                  @componentSolved="componentSolved"
                                  :lightCourseComponentIndex="lightCourseComponentIndex"
                                  :key="`parser-for-slide-${slideIndex}`"/>
          </transition>
        </div>
        <LightCourseBottomResult v-if="showResult"
                                 :wnr="wnr"
                                 :justNext="justNext"
                                 @next="next"/>
        <LightCourseResultPage v-if="showConfirmPage"
                               :slides-success-count="this.slidesProgress.success"
                               :slides-failed-count="this.slidesProgress.failed"
                               :lives-left="livesLeft"
                               :lives-initial="initialLives"
                               :words="wordsLearned"
                               :scores="scoresEarned"
                               :progress="progress"
                               @finish="finish"
                               @again="again"/>
        <lottie-player ref="lottie"
                       :key="`lottie-${lottieCounter}`"
                       mode="normal"
                       background="transparent"
                       speed="1"
                       class="lottie-fireworks"
                       direction="1"
                       src="https://lottie.host/bc246678-e355-4771-abf6-75bdb5201305/uNWYsrmITf.json">
        </lottie-player>
      </div>
      <div v-if="material && material.locked" class="container">
        <div class="vh-100 d-flex justify-content-center align-items-center">
          Exercise is not yet available
        </div>
      </div>
    </main>
  </section>
</template>

<script>
import TaskComponentsParser from "@/views/parts/class/preview/TaskComponentsParser"
import { LessonsService, StudentPageService } from "../../../../services/api.service"
import {mapActions, mapState} from "vuex"
import { tasksWithoutSolving } from "../../../../dictionaries/tasksWithoutSolving"
import LightCourseBottomResult from "../../../parts/courses/LightCourseBottomResult.vue"
import HeartSvg from '@/assets/svg/heart.svg'
import LightCourseResultPage from "../../../parts/courses/LightCourseResultPage.vue"
import {loadScript} from "vue-plugin-load-script"
import '@lottiefiles/lottie-player';

export default {
  name: "StudentLightCourseLessonRoom",
  components: {
    LightCourseResultPage,
    LightCourseBottomResult,
    TaskComponentsParser,
    HeartSvg
  },
  metaInfo() {
    return {
      title: `${this.material?.title || ''} / ${this.module?.title || ''}`,
    }
  },
  data() {
    return {
      slides: [],
      material: null,
      slideIndex: 0,
      lightCourseComponentIndex: 0,
      showResult: false,
      showConfirmPage: false,
      initialLives: 5,
      lives: 5,
      lives_wasted: 0,
      currentTaskDeductsLives: false,
      justNext: false,
      wnr: [],
      wordsLearned: 0,
      scoresEarned: 0,
      lottieCounter: 0,
      slidesProgress: {
        success: 0,
        failed: 0,
      },
    }
  },
  computed: {
    ...mapState({
      lightRepeat: state => state.call.lightRepeat,
    }),
    materialId() {
      return this.$route.params.materialId
    },
    lesson() {
      return this.material?.light_course_lesson || null
    },
    module() {
      return this.lesson ? this.lesson.module : null
    },
    livesLeft() {
      return this.lives - this.lives_wasted
    },
    progress() {
      const currentShare = this.lightRepeat ? 0 : (this.material?.progressShare || 0)
      return Math.min(100, (this.material?.light_course_lesson?.progress || 0) + currentShare)
    },
    currentSlide() {
      return this.slides[this.slideIndex] || null
    },
    currentSlideHasLives() {
      if(!this.currentSlide) return false
      return this.currentSlide.components.some(x => x.component?.light_lives)
    }
  },
  methods: {
    ...mapActions([
      'getUserTasks',
    ]),
    async loadSlides() {
      let res = await LessonsService.getSlidesForMaterial(this.materialId)
      this.slides = res?.data?.data?.slides?.lesson || []
      this.material = res?.data?.data?.material || null
      this.lives = this.material?.livesLeft || 5
    },
    async changeProgress(status = 'started', lives_wasted = 0, force = false) {
      return await StudentPageService.changeLightProgress(this.materialId, status, lives_wasted, force)
    },
    componentSolved(component, index, finished, wnr) {
      if(!finished) return
      const componentCode = component?.component_type?.code || ''
      this.wnr = wnr
      this.justNext = false
      if(tasksWithoutSolving.includes(componentCode)) {
        this.lightCourseComponentIndex = index + 1
        if((index + 1) === this.currentSlide.components.length) {
          this.justNext = true
          this.showResult = true
        }
      } else {
        this.currentTaskDeductsLives = component?.light_lives || false
        this.showResult = true
      }
    },
    next(correct) {
      if (correct || this.justNext) {
        this.slidesProgress.success++
      } else {
        this.slidesProgress.failed++
      }

      if(!correct && this.currentTaskDeductsLives) this.lives_wasted++
      this.justNext = false
      if(this.livesLeft <= 0) {
        this.showConfirmPage = true
        return
      }
      if((this.slideIndex + 1) === this.slides.length) {
        this.showConfirmPage = true
        this.playFireworks()
        return
      }
      this.showResult = false
      this.slideIndex++
      this.lightCourseComponentIndex = 0
    },
    async finish(go = false) {
      if(this.lightRepeat) {
        await LessonsService.saveLightTaskStorageBulk({
          material_id: this.materialId,
          results: this.$store.state.call.tasksStore
        })
      }
      const res = await this.changeProgress(this.livesLeft <= 0 ? 'failed' : 'finished', this.lives_wasted, this.lightRepeat)
      this.wordsLearned = res.data.data.words
      this.scoresEarned = res.data.data.scores
      if(go) this.goToLessons()
    },
    async again() {
      const resetWholeLesson = this.livesLeft <= 0
      this.$store.commit('setTasksStore', {})
      this.$store.commit('setLightRepeat', !resetWholeLesson)

      this.slidesProgress.success = 0;
      this.slidesProgress.failed = 0;

      if(resetWholeLesson) {
        await StudentPageService.resetLightLesson(this.materialId, resetWholeLesson)
        this.goToLessons()
        return
      }
      this.showConfirmPage = false
      this.showResult = false
      this.slideIndex = 0
      this.lives_wasted = 0
    },
    goToLessons() {
      this.$router.push({
        name: 'StudentLightCourseLessons',
        params: {
          courseId: this.module.light_course_id,
          moduleId: this.module.id,
        }
      })
    },
    async playFireworks() {
      this.lottieCounter++
      await this.$nextTick()
      this.$refs.lottie.currentProgress = 0
      this.$refs.lottie.play()
    }
  },
  async mounted() {
    await loadScript('/rive@2.3.1.js')
    await this.loadSlides()
    if(this.material.locked) return
    this.$store.commit('setTasksStore', {})
    this.$store.commit('setIsHomework', false)
    this.$store.commit('setFollowed', false)
    this.$store.commit('setSocket', null)
    this.$store.commit('setViewMode', 'selfstudy')
    this.$store.commit('setMaterialId', this.materialId)
    this.$store.commit('setMethodics', 'enggo')
    const res = await this.changeProgress()
    this.$store.commit('setLightRepeat', res?.data?.data?.status === 'finished')
    if(!this.lightRepeat) await this.getUserTasks({ id: this.materialId, type: 'selfstudy' })
  },
  beforeDestroy() {
    this.$store.commit('setTasksStore', {})
    this.$store.commit('setLightRepeat', false)
  }
}
</script>

<style scoped lang="scss">
.room-section {
  width: 100vw;
  overflow-x: hidden;
}
.logo {
  max-width: 87px;
  width: 100%;
}
.progress-indicator {
  color: #B3B6BB;
  margin-top: 12px;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.b-progress {
  height: 8px;
}
.heart-text {
  font-family: 'Nunito';
  color: #FF143A;
  svg {
    width: 20px;
    fill: #FF143A;
    margin-right: 4px;
  }
}
.lottie-fireworks {
  user-select: none;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 5;
}

@media(max-width: 768px) {
  .logo {
    max-width: 75px;
  }
  .heart-text {
    svg {
      width: 16px;
    }
  }
}
</style>
